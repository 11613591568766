import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer';
// import img from "../../images/icons/camera.png";
import imgnew from "../../images/about/mission1.png"
import "./about.css";
import TopBanner from '../../components/topbanner/TopBanner';
import tools from "../../images/icons/tools.png"
import certification from "../../images/icons/certificate.png";
import pricing from "../../images/icons/pricing.png";
import sales from "../../images/icons/salesservice.png";
import expertise from "../../images/icons/expertise.png";
import support from "../../images/icons/support.png";
import { Link } from 'react-router-dom';



const About = () => {
    return (
        <>
            <Header />
            <TopBanner heading="About Us" />
            <section class=" bg-light  mt-0  d-flex align-items-center justify-content-center bg-text-muted">
                <div class="row container px-5 align-items-center mx-auto my-5">

                    <div class="col-lg-6 col-12  ">
                        <h5 style={{ color: "#26ACE2" }}>WHO WE ARE</h5>
                        {/* <h1><h1 class="text-center"><b style={{color:"#147EFB"}}>Our</b> <b>Products</b></h1> Prolinker</h1> */}
                        {/* <h1 ><b style={{ color: "#147EFB" }}>About </b> <b>ProLink</b></h1> */}
                        {/* <h6>With 30 years of experience in the sectors ranging from energy to communications, we excel in providing the top tier integration solutions with a view to satisfy the need for electrical installations, LT panel integrations and Automation/Instrumentation solutions under one platform</h6> */}
                        <h6>
                            <b style={{ color: "#147EFB" }}>PROLINK ENGINEERS</b>  <b>is a professional System Integration Company to offer High-end Communication & Security solutions to Industries & Corporate sectors. We are an established player in the Industry with pool Security Systems, Bullet CCTV Surveillance Solutions, Access Control, Alarm Systems, Biometrics systems, Fire Alarm Systems, Static Detectors & Motion Detection cameras, etc. We have earned a reputation for providing cost-effective solutions to satisfy customers' requirements. </b>

                        </h6>

                    </div>
                    <div class="col-lg-6 col-12  ">
                        <img src={require("../../images/about/about1.jpg")} class="img-fluid" alt="About Imagge" />
                    </div>
                </div>
            </section>


            <section class="believe py-5 " style={{ backgroundColor: "#26ACE2" }}>
                <div class="row container mx-auto my-5">
                    <h1 className='text-center'><b style={{ color: "#ffffff" }}>Our </b> <b>Values </b></h1>
                    <div class="col-lg-4 gx-5 gy-5">
                        <div class="card hovercard" style={{ width: "18rem" }}>
                            <div class="card-body text-center">
                                <img src={tools} class="py-3" alt="" />
                                <h4 class="card-subtitle mb-2 text-muted ">Right Tools</h4>
                                <p class="card-text">We have the right tools and technologies to offer you the best security systems.</p>
                                {/* <!-- <a href="#" class="card-link">Another link</a> --> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 gx-5 gy-5">
                        <div class="card hovercard" style={{ width: "18rem" }}>
                            <div class="card-body text-center">
                                <img src={certification} class="py-3" alt="" />
                                <h4 class="card-subtitle mb-2 text-muted ">Certifications</h4>
                                <p class="card-text">We have the best certifications and accredit from the various industry leaders.</p>
                                {/* <!-- <a href="#" class="card-link">Card link</a> --> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 gx-5 gy-5">
                        <div class="card hovercard" style={{ width: "18rem" }}>
                            <div class="card-body text-center">
                                <img src={pricing} class="py-3" alt="" />
                                <h4 class="card-subtitle mb-2 text-muted ">Competitive Pricing</h4>
                                <p class="card-text text-center">We are not just another security solution provider, we have the best pricing to stand ourselves out from the crowd.</p>
                                {/* <!-- <a href="#" class="card-link">Card link</a> --> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 gx-5 gy-5">
                        <div class="card hovercard" style={{ width: "18rem" }}>
                            <div class="card-body text-center">
                                <img src={sales} class="py-3" alt="" />
                                <h4 class="card-subtitle mb-2 text-muted ">After Sales Services</h4>
                                <p class="card-text text-center">We don’t just leave our customers in the hanging garden, we are always there to support them even after sales.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 gx-5 gy-5">
                        <div class="card hovercard" style={{ width: "18rem" }}>
                            <div class="card-body text-center">
                                <img src={expertise} class="py-3" alt="" />
                                <h4 class="card-subtitle mb-2 text-muted ">25+ Experience</h4>
                                <p class="card-text text-center">Our experience in the industry gives us the confidence to offer the best solutions to our customers.</p>
                                {/* @*<a href="#" class="card-link">Card link</a>*@ */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 gx-5  gy-5">
                        <div class="card hovercard" style={{ width: "18rem" }}>
                            <div class="card-body text-center">
                                <img src={support} class="py-3" alt="" />
                                <h4 class="card-subtitle mb-2 text-muted ">Great Support</h4>
                                <p class="card-text text-center">Our support team is available 24/7 to enhance your experience with us.</p>
                                {/* @*<a href="#" class="card-link">Card link</a>*@ */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="bg-light">
                <section class="who_we container p-5 ">
                    <div class="text-center ">
                        <h1 className='text-center'><b style={{ color: "#26ACE2" }}>Goals &</b> <b>Objectives</b></h1>
                        {/* <h3>Goals & Objectives</h3> */}
                        <p>
                            As we are a one-of-a-kind security solutions provider in the industry, our goal is to provide 100% customer satisfaction to our customers by consistently delivering high-end communication & security solutions with our years of experience and expertise.
                        </p>
                    </div>
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-12" style={{
                                // backgroundImage: `url(${imgnew})`,
                                 backgroundRepeat: 'no-repeat',
                                height: "580px",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}>
                            {/* <div class="col-lg-4" > */}
                                <img src={imgnew} style={{ width: "100%" ,height:"100%"}} class="card-img-top" alt="..."/>
                            </div>
                            <div class="col col-lg-8 py-5">
                                <Link  class="text-dark nav-link " style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                    <div class="bg-light">
                                        <div class=" bg-light who_we_col p-2 my-3 d-flex justify-content-between" style={{ border: "0px 15px 15px 0px" }}>
                                            <b>VISION</b>
                                            <b class="text-primary" style={{ fontSize: "1.2rem" }}><i class="fas fa-mail-bulk"></i></b>

                                        </div>
                                        <div class="px-3">
                                            <p>
                                                Our vision is to continuously improve our services to enhance your security and privacy. We will accomplish this by consistently offering the highest level of integrated security system solutions.
                                            </p>
                                        </div>
                                    </div>

                                </Link>
                                <Link  class="text-dark nav-link my-3" style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                    <div class="bg-light">
                                        <div class=" bg-light who_we_col p-3 my-3 d-flex justify-content-between" style={{ borderBottomLeftRadius: "15px", borderTopLeftRadius: "15px" }}>
                                            <b>MISSION</b>
                                            <b class="text-primary" style={{ fontSize: "1.2rem" }}><i class="fas fa-bullseye"></i></b>
                                        </div>
                                        <div class="px-3">
                                            <p>
                                                Our mission is to provide high-end communication products and solutions, tailored to the needs of the client. Every product shall be constructed to fulfill its purpose while protecting the security and privacy of our customers.
                                            </p>
                                        </div>
                                    </div>

                                </Link>
                                <a href="#" class="text-dark nav-link " style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                                    <div class="bg-light">
                                        <div class=" bg-light who_we_col p-3 my-3 d-flex justify-content-between" style={{ borderBottomLeftRadius: "15px", borderTopLeftRadius: "15px" }}>
                                            <b>CORE VALUES</b>
                                            <b class="text-primary" style={{ fontSize: "1.2rem" }}><i class="fas fa-mail-bulk"></i></b>

                                        </div>
                                        <div class="px-3">
                                            <p>
                                                We are one of the leading system integration companies to provide customized and tailor-made communication & security solutions for industries & corporate sectors.
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </>
    )
}

export default About