import React, { useEffect } from 'react';
import "./productcard.css";
import { Link } from 'react-router-dom';

const ProductCard = ({ name, content, link, imgsrc }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {/* <h1>Flexbox Blog-Card</h1> */}

            {/* <div className="container">


            </div> */}




            <div class="blog-wrapper ">
                <div class="blog-card">
                    <div class="card-img">
                        <img src={imgsrc} className="img-fluid" alt={imgsrc} />
                    </div>
                    {/* <div class="card-details"><span><i class="fa fa-calendar"></i>AUG 4</span><span><i class="fa fa-heart"></i>102</span></div> */}
                    <div>
                    <h1 className='card-text' style={{ color: "#26ACE2",fontSize:"25px",fontWeight:"700",marginTop:"30px" }}>{name}</h1>
                    {/* <div class="card-text"><p>{content}</p></div> */}

                    <Link to={`${link}`}>
                        <div class="btn btn-primary px-4 read-more">

                            View</div>
                    </Link>
                        
                    </div>
                   


                </div>

            </div>
        </>
    )
}

export default ProductCard