export default [
    {
        Id: 1,
        imgsrc: require(`../../images/solution/s1.png`),
        Heading: "BUILDING MANAGEMENT SYSTEM",
        productDescription: `
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet enim tortor at auctor urna nunc. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis feugiat vivamus at augue eget arcu. Vulputate eu scelerisque felis imperdiet proin. Morbi non arcu risus quis varius quam quisque id diam. Vitae suscipit tellus mauris a diam maecenas sed enim ut. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Imperdiet proin fermentum leo vel orci porta non pulvinar. Id aliquet risus feugiat in ante. Nullam non nisi est sit amet facilisis magna etiam. Tristique senectus et netus et malesuada fames ac. Amet nulla facilisi morbi tempus iaculis urna. Commodo nulla facilisi nullam vehicula ipsum a. Quis eleifend quam adipiscing vitae proin sagittis.
     a condimentum vitae sapien pellentesque habitant morbi. Justo donec enim diam vulputate ut pharetra sit amet. Turpis tincidunt id aliquet risus feugiat in ante. Posuere morbi leo urna molestie at. Vestibulum sed arcu non odio euismod lacinia at quis risus. Habitant morbi tristique senectus et netus. Velit scelerisque in dictum non consectetur. Sit amet venenatis urna cursus eget nunc scelerisque viverra. Proin nibh nisl condimentum id venenatis a condimentum. In ornare quam viverra orci sagittis. Cras adipiscing enim eu turpis egestas pretium. Nullam ac tortor vitae purus faucibus ornare suspendisse. Cursus eget nunc scelerisque viverra.
        `,

        url: "building-management-system"
    },
    {
        Id: 2,
        imgsrc: require(`../../images/solution/s2.jpg`),
        Heading: "VIDEO CONFERENCING SYSTEM",
        productDescription: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet enim tortor at auctor urna nunc. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis feugiat vivamus at augue eget arcu. Vulputate eu scelerisque felis imperdiet proin. Morbi non arcu risus quis varius quam quisque id diam. Vitae suscipit tellus mauris a diam maecenas sed enim ut. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Imperdiet proin fermentum leo vel orci porta non pulvinar. Id aliquet risus feugiat in ante. Nullam non nisi est sit amet facilisis magna etiam. Tristique senectus et netus et malesuada fames ac. Amet nulla facilisi morbi tempus iaculis urna. Commodo nulla facilisi nullam vehicula ipsum a. Quis eleifend quam adipiscing vitae proin sagittis.
 a condimentum vitae sapien pellentesque habitant morbi. Justo donec enim diam vulputate ut pharetra sit amet. Turpis tincidunt id aliquet risus feugiat in ante. Posuere morbi leo urna molestie at. Vestibulum sed arcu non odio euismod lacinia at quis risus. Habitant morbi tristique senectus et netus. Velit scelerisque in dictum non consectetur. Sit amet venenatis urna cursus eget nunc scelerisque viverra. Proin nibh nisl condimentum id venenatis a condimentum. In ornare quam viverra orci sagittis. Cras adipiscing enim eu turpis egestas pretium. Nullam ac tortor vitae purus faucibus ornare suspendisse. Cursus eget nunc scelerisque viverra.
    `,

        url: "video-conferencing-system"
    },

    {
        Id: 4,
        imgsrc: require(`../../images/solution/s4.jpg`),
        Name: "Bharat Petroleum",
        Heading: "IT INFRASTRUCTURE TECHNOLOGIES",
        productDescription: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet enim tortor at auctor urna nunc. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis feugiat vivamus at augue eget arcu. Vulputate eu scelerisque felis imperdiet proin. Morbi non arcu risus quis varius quam quisque id diam. Vitae suscipit tellus mauris a diam maecenas sed enim ut. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Imperdiet proin fermentum leo vel orci porta non pulvinar. Id aliquet risus feugiat in ante. Nullam non nisi est sit amet facilisis magna etiam. Tristique senectus et netus et malesuada fames ac. Amet nulla facilisi morbi tempus iaculis urna. Commodo nulla facilisi nullam vehicula ipsum a. Quis eleifend quam adipiscing vitae proin sagittis.
 a condimentum vitae sapien pellentesque habitant morbi. Justo donec enim diam vulputate ut pharetra sit amet. Turpis tincidunt id aliquet risus feugiat in ante. Posuere morbi leo urna molestie at. Vestibulum sed arcu non odio euismod lacinia at quis risus. Habitant morbi tristique senectus et netus. Velit scelerisque in dictum non consectetur. Sit amet venenatis urna cursus eget nunc scelerisque viverra. Proin nibh nisl condimentum id venenatis a condimentum. In ornare quam viverra orci sagittis. Cras adipiscing enim eu turpis egestas pretium. Nullam ac tortor vitae purus faucibus ornare suspendisse. Cursus eget nunc scelerisque viverra.
    `,

        url: "it-infrastructure-technologies"
    },
    {
        Id: 5,
        imgsrc: require(`../../images/solution/s5.jpg`),
        Name: "Toyoto Tsusho",
        Heading: "CCTV AND SURVEILLANCE SYSTEM",
        productDescription: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet enim tortor at auctor urna nunc. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis feugiat vivamus at augue eget arcu. Vulputate eu scelerisque felis imperdiet proin. Morbi non arcu risus quis varius quam quisque id diam. Vitae suscipit tellus mauris a diam maecenas sed enim ut. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Imperdiet proin fermentum leo vel orci porta non pulvinar. Id aliquet risus feugiat in ante. Nullam non nisi est sit amet facilisis magna etiam. Tristique senectus et netus et malesuada fames ac. Amet nulla facilisi morbi tempus iaculis urna. Commodo nulla facilisi nullam vehicula ipsum a. Quis eleifend quam adipiscing vitae proin sagittis.
 a condimentum vitae sapien pellentesque habitant morbi. Justo donec enim diam vulputate ut pharetra sit amet. Turpis tincidunt id aliquet risus feugiat in ante. Posuere morbi leo urna molestie at. Vestibulum sed arcu non odio euismod lacinia at quis risus. Habitant morbi tristique senectus et netus. Velit scelerisque in dictum non consectetur. Sit amet venenatis urna cursus eget nunc scelerisque viverra. Proin nibh nisl condimentum id venenatis a condimentum. In ornare quam viverra orci sagittis. Cras adipiscing enim eu turpis egestas pretium. Nullam ac tortor vitae purus faucibus ornare suspendisse. Cursus eget nunc scelerisque viverra.
    `,

        url: "cctv-surveillance-system"
    },
    {
        Id: 6,
        imgsrc: require(`../../images/solution/s6.jpeg`),
        Name: "Covestro",
        Heading: "FIRE DETECTION AND ALARM SYSTEM",
        productDescription: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet enim tortor at auctor urna nunc. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis feugiat vivamus at augue eget arcu. Vulputate eu scelerisque felis imperdiet proin. Morbi non arcu risus quis varius quam quisque id diam. Vitae suscipit tellus mauris a diam maecenas sed enim ut. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Imperdiet proin fermentum leo vel orci porta non pulvinar. Id aliquet risus feugiat in ante. Nullam non nisi est sit amet facilisis magna etiam. Tristique senectus et netus et malesuada fames ac. Amet nulla facilisi morbi tempus iaculis urna. Commodo nulla facilisi nullam vehicula ipsum a. Quis eleifend quam adipiscing vitae proin sagittis.
 a condimentum vitae sapien pellentesque habitant morbi. Justo donec enim diam vulputate ut pharetra sit amet. Turpis tincidunt id aliquet risus feugiat in ante. Posuere morbi leo urna molestie at. Vestibulum sed arcu non odio euismod lacinia at quis risus. Habitant morbi tristique senectus et netus. Velit scelerisque in dictum non consectetur. Sit amet venenatis urna cursus eget nunc scelerisque viverra. Proin nibh nisl condimentum id venenatis a condimentum. In ornare quam viverra orci sagittis. Cras adipiscing enim eu turpis egestas pretium. Nullam ac tortor vitae purus faucibus ornare suspendisse. Cursus eget nunc scelerisque viverra.
    `,

        url: "fire-detection-and-alarm-system"
    },
    {
        Id: 7,
        imgsrc: require(`../../images/solution/s7.jpg`),
        Name: "Dangote",
        Heading: "IP PAGA AND EMERGENCY COMMUNICATION SYSTEM",
        productDescription: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet enim tortor at auctor urna nunc. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis feugiat vivamus at augue eget arcu. Vulputate eu scelerisque felis imperdiet proin. Morbi non arcu risus quis varius quam quisque id diam. Vitae suscipit tellus mauris a diam maecenas sed enim ut. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Imperdiet proin fermentum leo vel orci porta non pulvinar. Id aliquet risus feugiat in ante. Nullam non nisi est sit amet facilisis magna etiam. Tristique senectus et netus et malesuada fames ac. Amet nulla facilisi morbi tempus iaculis urna. Commodo nulla facilisi nullam vehicula ipsum a. Quis eleifend quam adipiscing vitae proin sagittis.
 a condimentum vitae sapien pellentesque habitant morbi. Justo donec enim diam vulputate ut pharetra sit amet. Turpis tincidunt id aliquet risus feugiat in ante. Posuere morbi leo urna molestie at. Vestibulum sed arcu non odio euismod lacinia at quis risus. Habitant morbi tristique senectus et netus. Velit scelerisque in dictum non consectetur. Sit amet venenatis urna cursus eget nunc scelerisque viverra. Proin nibh nisl condimentum id venenatis a condimentum. In ornare quam viverra orci sagittis. Cras adipiscing enim eu turpis egestas pretium. Nullam ac tortor vitae purus faucibus ornare suspendisse. Cursus eget nunc scelerisque viverra.
    `,

        url: "emergency-communication-system"
    },
    {
        Id: 8,
        imgsrc: require(`../../images/solution/s8.jpg`),
        Name: "Engineers India Limited",
        Heading: "INTEGRATED CRITICAL COMMUNICATION  SYSTEMS",
        productDescription: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet enim tortor at auctor urna nunc. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis feugiat vivamus at augue eget arcu. Vulputate eu scelerisque felis imperdiet proin. Morbi non arcu risus quis varius quam quisque id diam. Vitae suscipit tellus mauris a diam maecenas sed enim ut. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Imperdiet proin fermentum leo vel orci porta non pulvinar. Id aliquet risus feugiat in ante. Nullam non nisi est sit amet facilisis magna etiam. Tristique senectus et netus et malesuada fames ac. Amet nulla facilisi morbi tempus iaculis urna. Commodo nulla facilisi nullam vehicula ipsum a. Quis eleifend quam adipiscing vitae proin sagittis.
 a condimentum vitae sapien pellentesque habitant morbi. Justo donec enim diam vulputate ut pharetra sit amet. Turpis tincidunt id aliquet risus feugiat in ante. Posuere morbi leo urna molestie at. Vestibulum sed arcu non odio euismod lacinia at quis risus. Habitant morbi tristique senectus et netus. Velit scelerisque in dictum non consectetur. Sit amet venenatis urna cursus eget nunc scelerisque viverra. Proin nibh nisl condimentum id venenatis a condimentum. In ornare quam viverra orci sagittis. Cras adipiscing enim eu turpis egestas pretium. Nullam ac tortor vitae purus faucibus ornare suspendisse. Cursus eget nunc scelerisque viverra.
    `,

        url: "integrated-critical-communication-system"
    },
    {
        Id: 3,
        imgsrc: require(`../../images/solution/s3.jpg`),
        Name: "BHEL",
        Heading: "FIXED GAS DETECTION",
        productDescription: `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquet enim tortor at auctor urna nunc. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis feugiat vivamus at augue eget arcu. Vulputate eu scelerisque felis imperdiet proin. Morbi non arcu risus quis varius quam quisque id diam. Vitae suscipit tellus mauris a diam maecenas sed enim ut. At risus viverra adipiscing at in tellus integer feugiat scelerisque. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Imperdiet proin fermentum leo vel orci porta non pulvinar. Id aliquet risus feugiat in ante. Nullam non nisi est sit amet facilisis magna etiam. Tristique senectus et netus et malesuada fames ac. Amet nulla facilisi morbi tempus iaculis urna. Commodo nulla facilisi nullam vehicula ipsum a. Quis eleifend quam adipiscing vitae proin sagittis.
 a condimentum vitae sapien pellentesque habitant morbi. Justo donec enim diam vulputate ut pharetra sit amet. Turpis tincidunt id aliquet risus feugiat in ante. Posuere morbi leo urna molestie at. Vestibulum sed arcu non odio euismod lacinia at quis risus. Habitant morbi tristique senectus et netus. Velit scelerisque in dictum non consectetur. Sit amet venenatis urna cursus eget nunc scelerisque viverra. Proin nibh nisl condimentum id venenatis a condimentum. In ornare quam viverra orci sagittis. Cras adipiscing enim eu turpis egestas pretium. Nullam ac tortor vitae purus faucibus ornare suspendisse. Cursus eget nunc scelerisque viverra.
    `,

        url: "fixed-gas-detection"
    },

]