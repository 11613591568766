import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Contactus from "./pages/contact/Contactus";
import About from "./pages/About/About";
import Gallery from "./pages/gallary/Gallery";
import Product from "./pages/productdetails/Product";
import Products from "./pages/productdetails/Products";
import Smart4G from "./pages/productdetails/products/Smart4G";
import SmartBullet from "./pages/productdetails/products/SmartBullet";
import SmartWifi from "./pages/productdetails/products/SmartWifi";
import Client from "./components/Client/Client";




function App() {
  return (
   <>
   <BrowserRouter>
   <Routes>
   <Route path="/" element={<Home/>} />
   <Route path="/contact" element={<Contactus/>} />
   <Route path="/about" element={<About/>} />
   <Route path="/gallery" element={<Gallery/>} />
   <Route path="/product/:name" element={<Product/>} />
   <Route path="/products" element={<Products/>} />
   <Route path="/smart-4g" element={<Smart4G/>} />
   <Route path="/smart-bullet" element={<SmartBullet/>} />
   <Route path="/smart-wifi" element={<SmartWifi/>} />
   <Route path="/client" element={<Client/>} />
   </Routes>
   </BrowserRouter>
   </>
  );
}

export default App;
