import React from 'react';
import Header from '../../components/Header/Header';
// import HomeCard from '../../components/homecard/HomeCard';
// import Achievment from '../../components/Achievment/Achievment';
// import WhatWedo from '../../components/whatwedo/WhatWedo';
// import OurClient from '../../components/ourclient/OurClient';
import Review from '../../components/Review/Review';
import Footer from '../../components/Footer/Footer';
import Homevideo from '../../components/videocontainer/Homevideo';
import BelowLine from '../../components/belowline/BelowLine';
// import ProductCard from '../productdetails/ProductCard';

import { MdLocalAtm, MdOutlineDoubleArrow } from "react-icons/md";
import "../../App.css"
import ProductCard from '../productdetails/ProductCard';
import OurClient from '../../components/ourclient/OurClient';
import { servilliancedata } from "./Surveillance.js";
import FooterContact from '../../components/FooterContact/FooterContact';
import Award from '../../components/award/Award';
import { IoMdArrowRoundForward } from "react-icons/io";
import { FcDam } from 'react-icons/fc';




const Solution = ({ c }) => {

  return (
    <>

      {/* <h1 style={{color:"red"}}>{c?.Name}</h1> */}

      <div class="col-lg-3 col-md-3 col-6 text-center  p-3 " key={c?.Id} >
        <div class="mybox1 text-center p-3 " style={{backgroundColor:"white"}} >
          {c.icon}
          <p><b>{c?.Name}</b></p>
          <div className='' style={{backgroundColor:"rgb(38, 172, 226)",borderRadius:"13px"}}>
          <p className='text-center p-1' ><b>Read More <IoMdArrowRoundForward/></b></p>
          {/* <a href="" className='btn  ' style={{backgroundColor:"rgb(38, 172, 226)"}}>
          <b >Read More <IoMdArrowRoundForward/></b>
          </a> */}
          
          </div>
        </div>
      </div>

    </>

  )
}






const Home = () => {
  const products = [
    {
      Id: 1,
      imgsrc: require(`../../images/clients/c1.jpg`),
      Name: "ATM",
      icon: <FcDam size={77} className="p-1 " color='#26ACE2' />
    },
    {
      Id: 2,
      imgsrc: require(`../../images/clients/c2.jpg`),
      Name: "BFSI BRANCHES",
      icon: <MdLocalAtm size={77} className="p-1" color='#26ACE2'/>
    },
    {
      Id: 3,
      imgsrc: require(`../../images/clients/c3.jpg`),
      Name: "GOLD LOAN",
      icon: <MdLocalAtm size={77} className="p-1" color='#26ACE2'/>
    },
    {
      Id: 4,
      imgsrc: require(`../../images/clients/c4.jpg`),
      Name: "FACTORIES",
      icon: <MdLocalAtm size={77} className="p-1" color='#26ACE2' />
    },
    {
      Id: 5,
      imgsrc: require(`../../images/clients/c4.jpg`),
      Name: "FACTORIES",
      icon: <MdLocalAtm size={77} className="p-1" color='#26ACE2'/>
    },
    {
      Id: 6,
      imgsrc: require(`../../images/clients/c4.jpg`),
      Name: "FACTORIES",
      icon: <MdLocalAtm size={77} className="p-1" color='#26ACE2'/>
    },
    {
      Id: 7,
      imgsrc: require(`../../images/clients/c4.jpg`),
      Name: "FACTORIES",
      icon: <MdLocalAtm size={77} className="p-1" color='#26ACE2'/>
    },

  ]
  return (

    <>
      <Header />
      <Homevideo />

      <section className='py-5'>
        <div className="container">
          <div className="row ">
            <h2 className='text-center font-weight-900' ><b>Prevent Crime Before it Happens</b></h2>
            <BelowLine />
            <div className="col-md-6 py-5">
              <h2 className='fon' style={{ fontWeight: "900" }}>Security Guards are unreliable, inefficient and expensive.</h2>
              <p>Securens is the pioneer of the highly successful <span style={{ fontWeight: "700" }}>ACTIVE DETERRENCE™ </span>technology based on Human Detection Video Analytics that not only detects intrusion instantly on a real-time basis but also prevents it immediately using Video Verification, Lights and Sound.</p>
              <p><span style={{ fontWeight: "700" }}>ACTIVE DETERRENCE™ </span>of Securens has almost 100% success rate.</p>
              <p>Top Banks, retailers, warehouse, factories, retailers, QSRs, FNB and commercial establishments have given us repeat orders in last 5 years.</p>
            </div>
            <div className="col-md-6 py-5">
              <img src="https://www.securens.in/wp-content/uploads/elementor/thumbs/sleeping-guard-image-home-1-p7oqsganl0rmeaub2x8il0o1bwds5hfy5ncelwuwhs.png" alt="Camera" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

      {/* Explore Section */}
      <section className='py-5 primary-bg-color' >
        <div className="container">
          <div className="row ">
            <h2 className='text-center font-weight-900' ><b>Explore how companies have saved up to 60% of security costs</b></h2>
            <BelowLine />
            <div className="col-md-6 py-5">
              <img src="https://www.securens.in/wp-content/uploads/elementor/thumbs/home-banner-2-1-newone-osqx95knjjdcscg8v84wzefti42e7d0xg8e4xsdzb4.jpg" alt="Camera" className='img-fluid' />
            </div>
            <div className="col-md-6 py-5">
              <h2 className='fon' style={{ fontWeight: "900" }}>Security Guards are unreliable expensive.</h2>
              <p><MdOutlineDoubleArrow size={27} />ProLink is the pioneer of the highly successful <span style={{ fontWeight: "700" }}>ACTIVE DETERRENCE™ </span>technology based on Human Detection Video Analytics that not only  Lights and Sound.</p>
              <p><MdOutlineDoubleArrow size={27} />Top Banks, retailers, warehouse, factories, retailers, QSRs, FNB and commercial establishments have given us repeat orders in last 5 years.</p>
              <a href="" className='btn btn-primary'>Learn More</a>
            </div>
          </div>
        </div>
      </section>


      {/* Alarm Monitring */}

      <section className='py-5'>
        <div className="container">
          <div className="row ">
            <h2 className='text-center font-weight-900' ><b>Why Professional Alarm Monitoring is important</b></h2>
            <BelowLine />
            <div className="col-md-6 py-5">
              {/* <h2 className='fon' style={{ fontWeight: "900" }}>Security Guards are unreliable, inefficient and expensive.</h2> */}
              <p><span className='font-weight-900'>ProLink</span> is the pioneer of the highly successful <span style={{ fontWeight: "700" }}>ACTIVE DETERRENCE™ </span>technology based on Human Detection Video Analytics that not only detects intrusion instantly on a real-time basis but also prevents it immediately using Video Verification, Lights and Sound.</p>
              <p><span style={{ fontWeight: "700" }}>ACTIVE DETERRENCE™ </span>of Securens has almost 100% success rate.</p>
              <p>Top Banks, retailers, warehouse, factories, retailers, QSRs, FNB and commercial establishments have given us repeat orders in last 5 years.</p>
              <a href="" className='btn btn-primary'>Learn More</a>
            </div>
            <div className="col-md-6 py-5">
              <img src="https://www.securens.in/wp-content/uploads/elementor/thumbs/sleeping-guard-image-home-1-p7oqsganl0rmeaub2x8il0o1bwds5hfy5ncelwuwhs.png" alt="Camera" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

      {/* Industry Solution Section */}

      <section className='py-5 primary-bg-color' >
        <div className="container">
          <div className="row">
            <h2 className='text-center font-weight-900' ><b>Industry Solutions with meaningful ROI</b></h2>
            <BelowLine />
          </div>
          <div className="row mt-2">
            {
              products.map((p) => (
                <>
                  <Solution c={p} />

                </>
              ))
            }
          </div>
        </div>
      </section>




      <section className='py-5'>
        <div className="container">
          <div className="row ">
            {/* <h2 className='text-center font-weight-900' ><b>Prevent Crime Before it Happens</b></h2>
            <BelowLine /> */}
            <div className="col-md-6 py-5">
              <h2 className='fon' style={{ fontWeight: "900" }}>Why ProLink?</h2>
              <p><MdOutlineDoubleArrow size={27} />ProLink is the pioneer of the highly successful <span style={{ fontWeight: "700" }}>ACTIVE DETERRENCE™ </span>technology based on Human Detection Video Analytics that not only  Lights and Sound.</p>
              <p><MdOutlineDoubleArrow size={27} />Top Banks, retailers, warehouse, factories, retailers, QSRs, FNB and commercial establishments have given us repeat orders in last 5 years.</p>

              <p><MdOutlineDoubleArrow size={27} />ProLink is the pioneer of the highly successful <span style={{ fontWeight: "700" }}>ACTIVE DETERRENCE™ </span>technology based on Human Detection Video Analytics that not only  Lights and Sound.</p>


            </div>
            <div className="col-md-6 py-5">
              <img src="https://www.securens.in/wp-content/uploads/elementor/thumbs/sleeping-guard-image-home-1-p7oqsganl0rmeaub2x8il0o1bwds5hfy5ncelwuwhs.png" alt="Camera" className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

      {/* Serveillance Provider Section */}



      <section className='py-5 primary-bg-color' >
        <div className="container">
          <div className="row">
            <h2 className='text-center font-weight-900' ><b>India’s Largest E-Surveillance Provider</b></h2>
            <BelowLine />
          </div>
          <div className="row mt-3">
            {
              servilliancedata.map((p) => (
                <>

                  
                  <div className="col-md-3 text-center p-4">
                  <span  style={{ borderRadius: "50%", background: "#FDEFE8",fontSize:"3.2rem",padding:"18px" }}>{p.icon}</span>
                    
                    <h5 className='font-weight-600 mt-3'>{p.Name}</h5>
                  </div>
                </>
              ))
            }
          </div>
        </div>
      </section>
      <section>

        <Award />
        <FooterContact />
      </section>


      <Footer />


    </>
  )
}

export default Home