import React,{useState} from 'react';
import axios from 'axios';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import TopBanner from '../../components/topbanner/TopBanner';


const Contactus = () => {
    const [lastname, setLastName] = useState();
    const [name, setName] = useState();
    const [phone, setphone] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();

    const navigate = useNavigate()

   const iframeSource = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27793.995193214407!2d77.68718865613737!3d29.450726874685152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c1baa5ab31ec5%3A0xd7e1be6b42baf027!2sS.%20S.%20ENTERPRISES!5e0!3m2!1sen!2sin!4v1647946680580!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'

    const handleSubmit = (e) => {
        e.preventDefault();
        alert("form submitted Successfully")
        axios.get(`http://api.digisidekick.com/api/Mail?Email=aarjav@digisidekick.com&Firstname=${name}&Lastname= ${lastname}&Number=${phone}&Usermial=${email}&UserMessage=${message}&other=null`,).then((res) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(res.data)
               
            }).catch((error) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(error)
            });

        
        setName("");
        setLastName("");
        setphone('');
        setEmail("")
        setMessage("");
        navigate("/thank-you")
    
    }



    return (
        <>
            <Header />
            <TopBanner heading="Contact US"/>
            <section class="contact mb-4 pt-5 d-flex justify-content-center align-items-center" style={{ overflow: 'hidden' }}>
                <div class="container mx-auto">
                    <div class="row px-lg-5 px-2 p-5 g-5">
                        <div class="col-lg-6 col-md-6  mx-auto rounded bg-white" style={{ boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                            <h2 class="display-5 pt-2">Contact us with your queries below.</h2>
                            <small>To make your Client’s Event Memorable, Fill out the Form Below.</small>
                            <form class="py-2" method="post" onSubmit={handleSubmit}>

                                <div class="row">
                                    <div class="col-lg-6 my-2">
                                        <input type="text" class="form-control" placeholder="First Name" aria-label="First name" id="firstname" value={name} onChange={(e) => setName(e.target.value)} name="firstname" required />
                                    </div>
                                    <div class="col-lg-6 my-2">
                                        <input type="text" class="form-control" placeholder="Last Name" aria-label="Last name" value={lastname} onChange={(e) => setLastName(e.target.value)} id="lastname" name="lastname" required />
                                    </div>
                                </div>
                                <div class="form-row my-2">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} aria-label="Email" id="email" name="email" required />
                                    </div>
                                </div>
                                <div class="form-row my-2">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Phone Number" value={phone} onChange={(e) => setphone(e.target.value)} aria-label="Phone Number" id="number" name="number" required />
                                    </div>
                                </div>
                                <div class="form-floating my-3">
                                    <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" value={message} onChange={(e) => setMessage(e.target.value)} style={{ height: '100px' }} name="comment" ></textarea>
                                    <label for="floatingTextarea2">Message</label>
                                </div>

                                <input class="btn  rounded col-lg-12 col-12 px-5 py-2" style={{ backgroundColor: '#26ACE2', color: '#fff' }} type="submit" value="Submit" id="submit" />
                            </form>

                            {/* <!-- <a href="#" class="btn col-lg-12 " style=" background-color:#ED1E22 "><b class="text-white">Submit</b></a> --> */}


                        </div>
                        <div class="col-lg-6 col-md-6  mx-auto rounded d-flex justify-content-center align-items-center " style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', backgroundColor: '#191919' }}>
                            {/* <img src={require('../images/natura/hang.png')} className="swing" alt="" /> */}
                            <div className="upper_head_about">
                                <h2>
                                    <b className="text-white" style={{ color: '#FEBD15' }}>
                                        INDIA'S
                                    </b><br />
                                    <b className="" style={{  color: '#26ACE2' }}>
                                        No.1
                                    </b><br />
                                    <b className="text-white" style={{ color: '' }}>
                                        DIGITAL INTEGRATION
                                    </b><br />
                                    <b style={{ color: '#26ACE2' }}>COMPANY</b>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="col-md-12" dangerouslySetInnerHTML={{__html: iframeSource}}>

            </div>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27793.995193214407!2d77.68718865613737!3d29.450726874685152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c1baa5ab31ec5%3A0xd7e1be6b42baf027!2sS.%20S.%20ENTERPRISES!5e0!3m2!1sen!2sin!4v1647946680580!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}


            <Footer />
        </>
    )
}

export default Contactus