import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useParams } from 'react-router-dom';
import imgsrc from "../../images/clients/qd.jpg";
import "./product.css";
// import JsonData from "./jsonProductDetails"
import TopBanner from '../../components/topbanner/TopBanner';
import homedata from '../../components/homecard/homedata';



const Product = () => {
    let { name } = useParams();

    const blogadata = homedata?.filter((b)=> b.url === name)

    // console.log("bb",blogadata)

    return (
        <>
            <Header />
            {/* <div className="container">
                <div className="row">
                    <h1>Blogs For New</h1>
                </div>
            </div> */}

            {/* <div className='about_banner'>
                <div className="container">
                    <div class="main_title_3 py-5"> <span><em></em></span>
                        <h2 className='text-white ' ><b>Blogs</b></h2>
                    </div>
                </div>
                
            </div> */}

            <TopBanner heading={blogadata[0]?.Heading}/>
            <section className='py-5 multi_course'>
                <div class="main_title_2 py-5"> <span><em></em></span>
                    <h2>{blogadata[0]?.Heading}</h2>
                </div>
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6 col-12">
                            <img src={imgsrc} alt="" className='img-fluid' />
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="text-muted text-start">
                                <p>
                                    {blogadata[0]?.productDescription}
                                </p>
                            </div>
                        </div>
                        <p className="text-muted text-start py-2">
                            All businesses have different priorities when it comes to their video conferencing software choice depending on their industry, size, budgets and key use cases. For some small businesses, cost is the primary consideration, while others put a premium on security, reliability and support — perks typically associated with enterprise. Be it Industry, Education, Healthcare, Retail or BFSI Sector, our custom designed solution fits every sector.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Product