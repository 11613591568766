import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import "./header.css";
import { HiOutlineMailOpen } from "react-icons/hi";
import { AiOutlinePhone } from "react-icons/ai";
import { FaBars ,FaTimes} from "react-icons/fa";





const Header = () => {

    const [mobile, setmobile] = useState(false)
    
    const btnn = () => {
        setmobile(!mobile)
        // e.persist();
        let btn = document.querySelector('.menu_btn');
        let navbar = document.querySelector('.nav_icon');
        // document.querySelector('.menu_btn').onclick = function () {
        navbar.classList.toggle('mobile');
        btn.classList.toggle('fa-times');
        setmobile(true)
        // }
    }
    return (
        <>
            <div>

                {/* <!-- <Header Start============ --> */}
                <div className=' d-none text-center text-white py-lg-2 d-lg-flex  align-items-center ' style={{ position: 'fixed', width: '100%', top: '0', left: '0', zIndex: '3', backgroundColor: '#26ACE2' }}>
                    <div className="col-md-6">

                    </div>
                    <div className="col-md-8 ">
                        <HiOutlineMailOpen size={23} />&nbsp; Info@prolinkengineers.com | <AiOutlinePhone size={23} />&nbsp;  +91 882-600-7816 
                    </div>

                </div>
                <header className='' >

                    <nav className="header_box px-5">

                        <div className="logo  rounded bg-white mobile_number">
                            {/* <h1>Sac<span style={{color:'gray',fontSize:'2.3rem',opacity:'0.3'}}>Export</span> </h1> */}
                            <Link to="/"> <img className='heade_logo' src={require("../../images/icons/logo.png")} width="100%" height="70px" alt="" /></Link>
                        </div>
                    
                        <div className="nav_icon  ">
                            <li><Link to="/">Home</Link></li>
                            {/* ====================== Mobile Drop */}
                            <li><Link to="/products">Products</Link></li>
                            {/* <li><Link to="/#why-us">Document</Link></li> */}
                            <li><Link to="/client">Clients</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact">Contact us</Link></li>
                            {/* <li><Link to="/gallery">Gallery</Link></li> */}
                            {/* <li><Link to="/login">Login</Link></li> */}
                        </div>
                        <div className="d-lg-none">
                            {
                                mobile ?<><FaTimes  onClick={btnn} size={30}/></>:<FaBars onClick={btnn} size={30}/>
                            }
                        
                        </div>
                        

                        {/* <i className="menu_btn fas fa-bars text-dark" ></i> */}

                    </nav>
                </header>
                {/* <!-- <Header End============= --> */}

            </div>
        </>
    )
}

export default Header