import React from 'react';
import "./footercontact.css";

const FooterContact = () => {
    return (
        <>
            <section class="contform " id="form">
                <div class="container mx-auto">
                    <div class="row py-5">
                        <div class="col-lg-5 col-12 text-start ">
                            
                            <h2 ><b>Request a Free <br /> Call Back</b></h2>
                            <small>Know more and place your order </small>
                        </div>
                        <div class="col-lg-7 col-12">
                            <form >
                                <div class="row g-3   align-items-center">
                                    <div class="col-lg-5 col-12">
                                        <input type="text" id="name" required placeholder="Name" name="name" class="form-control"  />
                                    </div>
                                    <div class="col-lg-5 col-12">
                                        <input type="text" id="number" minlength="10" maxlength="10" required placeholder="Contact Number" name="number" class="form-control" />
                                    </div>
                                    <div class="col-lg-5 col-12">
                                        <input type="email" id="email" required="required" placeholder="Email" name="email" 
                                            class="form-control" />
                                    </div>
                                    <div class="col-lg-5 col-12">
                                        <input type="text" id="message" required placeholder="Message" name="message"  class="form-control" />
                                    </div>
                                </div>
                                <div class="text-lg-start text-center py-3 ">
                                    <button className='btn btn-primary'>Submit</button>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default FooterContact