import { AiOutlinePercentage } from "react-icons/ai";
import { FaCameraRetro } from "react-icons/fa";
import { MdLocalAtm } from "react-icons/md";
import { FcBinoculars, FcCableRelease, FcCamcorder, FcCompactCamera, FcCustomerSupport, FcSupport } from "react-icons/fc";

export const servilliancedata = [
    {
        Id: 1,
        // imgsrc: require(`../../images/clients/c36.jpg`),
        Name:"Trusted by Top Brands",
        icon:<FcCustomerSupport size={70} className="p-1" color="#26ACE2"/>
    },{
        Id: 2,
        // imgsrc: require(`../../images/clients/c36.jpg`),
        Name:"Consistent Repeat Orders",
        icon:<FcBinoculars size={70} className="p-1" color="#26ACE2"/>
        

    },{
        Id: 3,
        // imgsrc: require(`../../images/clients/c36.jpg`),
        Name:"Best eSurveillance Provider",
        icon:<FcCamcorder size={70} className="p-1" color="#26ACE2"/>
        

    }
    ,{
        Id: 4,
        // imgsrc: require(`../../images/clients/c36.jpg`),
        Name:"PAN India Installation Service",
        icon:<FcCompactCamera size={70} className="p-1" color="#26ACE2"/>

    },
    ,{
        Id: 5,
        // imgsrc: require(`../../images/clients/c36.jpg`),
        Name:"PAN India Service",
        icon:<FcCableRelease size={70} className="p-1" color="#26ACE2"/>

    }
    ,{
        Id: 5,
        // imgsrc: require(`../../images/clients/c36.jpg`),
        Name:"PAN India Service",
        icon:<FcSupport size={70} className="p-1" />

    }
    ,
    
]