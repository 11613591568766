import React from 'react';
import "./topbanner.css";

const TopBanner = ({heading}) => {
    return (
        <>
            <div className='about_banner'>
                <div className="container">
                    <div class="main_title_3 py-5"> <span><em></em></span>
                        <h2 className='text-white ' ><b>{heading}</b></h2>
                    </div>
                </div>
                {/* <img src={require('../Home/images/banner-image-01.png').default} alt="" /> */}
            </div>

        </>
    )
}

export default TopBanner