import React from 'react'

const Homevideo = () => {
    return (
        <section style={{ width: '100%', position: "relative" }}>

            <video id="homepage_video" autoPlay loop muted playsinline width="100%" class="uk-cover"
                style={{ objectFit: 'cover', minHeight: '100vh' }}>
                <source type="video/mp4" data-id="1920" itemprop="embedUrl" src="https://player.vimeo.com/external/298988786.hd.mp4?s=9fde00cce9ad578f74373c884396f7c25ff4ab1b&amp;profile_id=175" />
            </video>
            <div style={{ position: "absolute", top: "65%", backgroundColor: "#a0cdeb80", left: "50%", transform: "translateX(-50%)", width: "70%",padding:"23px" }} className='d-flex justify-content-center'>
                <h1 style={{ fontSize: '3.0rem', fontWeight: "900" }}><b style={{ color: "black" }} className="text-center">Save 60% Of Security Costs</b></h1>
            </div>


        </section>
    )
}

export default Homevideo