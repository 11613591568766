import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import TopBanner from '../../../components/topbanner/TopBanner';
import { FaCheckDouble } from "react-icons/fa";
import domplastic from "../../../images/icons/bulletcamera.jpg"
import dompvandel from "../../../images/icons/domevandel.jpg"


const SmartWifi = () => {
    return (
        <>
            <Header />
            <TopBanner heading="Smart Wifi" />
            <div className="container">
                <div className="row p-5">
                    <div className="col-md-12">
                        <h1 className='text-center'><b style={{ color: "#26ACE2" }}>RANGE OF </b> <b>CCTV CAMERA</b></h1>
                    </div>
                </div>


            </div>

            <div className=''>
                <div className="container ">
                    <div className="row text-center align-items-center justify-content-center">
                        <div className="col-md-4 ">
                            <img src={domplastic} alt="" />
                            <h1 className='' style={{ color: "#26ACE2" }}><b>Dome Plastic</b></h1>


                            <p className='mx-5'>
                                <div className='text-start'>
                                    <b >
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />3.0 Megapixel IP Outdoor camera<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />SIM: 4G<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support 2D / 3D noise reduction<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />IR Range upto 30 mtr<br />
                                    </b>

                                </div>


                            </p>


                        </div>
                        <div className="col-md-4">
                            <img className='' src={dompvandel} alt="" />
                            <h1 className='' style={{ color: "#26ACE2" }}><b>Dome Vandal</b></h1>

                            <p className=' ms-5'>
                                <div className='text-start'>
                                    <b >
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />3.0 Megapixel IP Outdoor camera<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />SIM: 4G<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support 2D / 3D noise reduction<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />IR Range upto 30 mtr<br />
                                    </b>

                                </div>

                            </p>



                        </div>
                        {/* <div className="col-md-4">
                            <img src="http://prolinkengineers.com/wp-content/uploads/2022/08/aq.jpg" alt="" />
                            <h1 className='' style={{ color: "#26ACE2" }}><b>Bullet Camera</b></h1>

                            <p className=' ms-5'>
                                <div className='text-start'>
                                    <b >
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />3.0 Megapixel IP Outdoor camera<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />SIM: 4G<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Power consumption : less than 3W<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Remote config & Motion detect<br />
                                    </b>
                                </div>
                            </p>


                        </div> */}
                        {/* <h1 className='text-center'><b style={{ color: "#147EFB" }}>RANGE OF </b> <b>CCTV CAMERA</b></h1> */}

                    </div>

                </div>

            </div>

            <section className='bg-light'>
                <div className="container">
                    <div className="row tex-center p-5">
                        <h1 className='text-center'><b style={{ color: "#26ACE2" }}>Technical </b> <b>Features</b></h1>

                    </div>

                    <div className="row tex-center pb-5 ">
                        {/* <h1 className='text-center'><b style={{ color: "#147EFB" }}>Technical </b> <b>Features</b></h1> */}
                        <div className="col-md-6 text-center">
                            <p className=' mx-5'>
                                <div className='text-start'>
                                    <b >
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />3MP 1/3″ COMS Sensor.<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support dual stream: Main stream: 1920×1080, 1280×960, And 1280×720 Sub stream : D1 (704xS76)/360P (640×360)/CIF (352×288);<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Can preview and playback on mobile phone directly.<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support 128GB SD card<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support video record on TF card, support main stream/sub stream video  record,<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support motion detection video record<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Video saved on TF card can be played on computer with video player;<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />TF card video support audio record;<br />
                                        {/* <FaCheckDouble style={{ color: "#26ACE2" }} />Support 128GB SD card<br /> */}
                                    </b>
                                </div>
                            </p>


                        </div>
                        <div className="col-md-6 text-center">
                            <p className=' mx-5'>
                                <div className='text-start'>
                                    <b >
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />3.0 Megapixel IP Outdoor camera<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support WDR, 3D noise reduction, strong light restraint, backlight Compensation, auto white balance for different surveillance environments<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support audio input/Out (Optional) .<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support FTP function<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support mail alarm <br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Support mobile surveillance with (IOS, ANDROID), web, CMS;
                                        Network Type FOD-LTE, TOD-LTE, WCDMA/HSPA+, TD-SCDMA, GSM/GPRS/EDGE<br />
                                        <FaCheckDouble style={{ color: "#26ACE2" }} />Frequency Scope LTE 81/B3//B5//B40/B41 WCDMA B1/B2/B4/B5/B8 optional) GSM/GPRS/EDGE 850/900/1800/1900 optional) LTE FDD/TDD (Cat. 4) DL 150Mbps, UL 50Mbps<br />
                                        {/* <FaCheckDouble style={{ color: "#26ACE2" }} />USIM/SIM Micro SIM card slot. Prolink Communication and Security Solutions Pvt. Ltd. Plot No.6A, New DLF Industrial Area, Sector 31 Faridabad, Haryana-121003<br /> */}
                                    </b>
                                </div>
                            </p>

                        </div>

                    </div>

                </div>

            </section>

            <Footer />
        </>
    )
}

export default SmartWifi