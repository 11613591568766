import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import OurClient from '../ourclient/OurClient';
import TopBanner from '../topbanner/TopBanner';


const Client = () => {
  return (
    <>
      <Header />
      <TopBanner heading="Clients"/>
      {/* <div style={{marginTop:"130px"}}> */}
        <OurClient />
      {/* </div> */}
      <Footer />

    </>
  )
}

export default Client