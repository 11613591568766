export default [
    {
        Id: 1,
        imgsrc: require(`../../images/clients/c1.jpg`),
        Name:"HoneyWell"
    },
    {
        Id: 2,
        imgsrc: require(`../../images/clients/c2.jpg`),
        Name:"BGR Energy"
    },
    {
        Id: 3,
        imgsrc: require(`../../images/clients/c3.jpg`),
        Name:"BHEL"
    },
    {
        Id: 4,
        imgsrc: require(`../../images/clients/c4.jpg`),
        Name:"Bharat Petroleum"
    },
    {
        Id: 5,
        imgsrc: require(`../../images/clients/c5.jpg`),
        Name:"Toyoto Tsusho"
    },
    {
        Id: 6,
        imgsrc: require(`../../images/clients/c6.jpg`),
        Name:"Covestro"
    },
    {
        Id: 7,
        imgsrc: require(`../../images/clients/c7.jpg`),
        Name:"Dangote"
    },
    {
        Id: 8,
        imgsrc: require(`../../images/clients/c8.jpg`),
        Name:"Engineers India Limited"
    },
    {
        Id: 9,
        imgsrc: require(`../../images/clients/c9.jpg`),
        Name:"Emerson"
    },
    {
        Id: 10,
        imgsrc: require(`../../images/clients/c10.jpg`),
        Name:"Larsen & Toubro"
    },
    {
        Id: 11,
        imgsrc: require(`../../images/clients/c11.jpg`),
        Name:"Jindal Steel & Power"
    },
    {
        Id: 12,
        imgsrc: require(`../../images/clients/c12.jpg`),
        Name:"NTPC"
    },
    {
        Id: 13,
        imgsrc: require(`../../images/clients/c13.jpg`),
        Name:"Maruti Suzuki"
    },
    {
        Id: 14,
        imgsrc: require(`../../images/clients/c14.jpg`),
        Name:"Schneider"
    },
    {
        Id: 15,
        imgsrc: require(`../../images/clients/c15.jpg`),
        Name:"SIEMENS"
    },
    {
        Id: 16,
        imgsrc: require(`../../images/clients/c16.jpg`),
        Name:"EGCB"
    },
    {
        Id: 17,
        imgsrc: require(`../../images/clients/c17.jpg`),
        Name:"GAIL"
    },
    {
        Id: 18,
        imgsrc: require(`../../images/clients/c18.jpg`),
        Name:"Mor Raipur"
    },
    {
        Id: 19,
        imgsrc: require(`../../images/clients/c19.jpg`),
        Name:"ISRL"
    },
    {
        Id: 20,
        imgsrc: require(`../../images/clients/c20.jpg`),
        Name:"Smart City Faridabad"
    },
    {
        Id: 21,
        imgsrc: require(`../../images/clients/c21.jpg`),
        Name:"Bhusan"
    },
    {
        Id: 22,
        imgsrc: require(`../../images/clients/c22.jpg`),
        Name:"Technip"
    },
    {
        Id: 23,
        imgsrc: require(`../../images/clients/c23.jpg`),
        Name:"HP"
    },
    {
        Id: 24,
        imgsrc: require(`../../images/clients/c24.jpg`),
        Name:"ABB"
    },
    {
        Id: 25,
        imgsrc: require(`../../images/clients/c25.jpg`),
        Name:"CFCL"
    },
    {
        Id: 26,
        imgsrc: require(`../../images/clients/c26.jpg`),
        Name:"IOTAEC"
    },
    {
        Id: 27,
        imgsrc: require(`../../images/clients/c27.jpg`),
        Name:"Indian Oil"
    },
    {
        Id: 28,
        imgsrc: require(`../../images/clients/c28.jpg`),
        Name:"CAIRN"
    },
    {
        Id: 29,
        imgsrc: require(`../../images/clients/c29.jpg`),
        Name:"EIL"
    },
    {
        Id: 30,
        imgsrc: require(`../../images/clients/c30.jpg`),
        Name:"Aditya Birla"
    },
    {
        Id: 31,
        imgsrc: require(`../../images/clients/c31.jpg`),
        Name:"Smart City Bhubaneshwar"
    },
    {
        Id: 32,
        imgsrc: require(`../../images/clients/c32.jpg`),
        Name:"TOYO Engineering"
    },
    {
        Id: 33,
        imgsrc: require(`../../images/clients/c33.jpg`),
        Name:"Saint Gobain"
    },
    // {
    //     Id: 34,
    //     imgsrc: require(`../../images/clients/c34.jpg`),
    //     Name:"ONGC"
    // },
    {
        Id: 35,
        imgsrc: require(`../../images/clients/c35.jpg`),
        Name:"ONGC"
    },
    {
        Id: 36,
        imgsrc: require(`../../images/clients/c36.jpg`),
        Name:"NRL"
    },
   
];