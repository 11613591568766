import React from 'react';
import BelowLine from '../belowline/BelowLine';
import "./award.css";

const Award = () => {
    return (
        <section className='py-5 awardbg'>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <img src="https://www.securens.in/wp-content/uploads/2020/01/Monitoring-1.png" alt="" className='img-fluid'/>
                    </div>
                    <div className="col-md-8 ">
                        <h2 className='font-weight-900'>National & International Award-Winning Monitoring Center</h2>
                        <div className="row mt-lg-5 mt-0">
                            <div className="col-md-4 " >
                                <div className='text-center p-3' style={{borderRadius:"13px",backgroundColor:"#26ACE2"}}>
                                    <h5 className='font-weight-900'>22,000 +</h5>
                                    <h6 className='font-weight-900'>Sites</h6>
                                </div>
                            </div>
                            <div className="col-md-4 " >
                            <div className='text-center p-3' style={{borderRadius:"13px",backgroundColor:"#26ACE2"}}>
                                    <h5 className='font-weight-900'>22,000 +</h5>
                                    <h6 className='font-weight-900'>Sites</h6>
                                </div>
                            </div>
                            <div className="col-md-4 " >
                            <div className='text-center p-3'style={{borderRadius:"13px",backgroundColor:"#26ACE2"}}>
                                    <h5 className='font-weight-900'>22,000 +</h5>
                                    <h6 className='font-weight-900'>Sites</h6>
                                </div>
                            </div>
                        </div>



                        
                    </div>


                </div>
            </div>


        </section>
    )
}

export default Award