import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
   <>
    <div>
            {/* Footer=== */}
            {/* footer section */}
            <div className=" footer" style={{backgroundColor:"#000000"}}>
                <div className="container mt-5 text-center text-md-start text-white">
                    <div className="row justify-content-center align-items-center">
                        <div class="col-md-6 col-12 col-xl-3 mx-auto">
                            <div >
                                {/* <h1 style={{ color: '#010100' }}>Todor<span style={{ color: '#010100', fontSize: '2.3rem', opacity: '0.3' }}>.</span> </h1> */}
                                <Link to="/" className="m-0">
                                    <img className='mx-0 ' src={require("../../images/icons/logo.png")} height="90px" alt="" style={{display:"unset"}} />
                                </Link>
                            </div>
                            {/* 
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#010100", height: "2px" }}
                            /> */}
                            <p>
                            Prolink Engineers provides world-class and dedicated integrated solutions to its customers. We are constantly innovating to deliver the best solutions & services.
                            </p>
                            {/* <h6 class="text-uppercase fw-bold " style={{color:"#FF0204"}}>Connect</h6> */}
                            <h6 class="text-uppercase fw-bold text-white">Connect</h6>
                            <hr
                                class=" mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#ffffff", height: "6px" }}
                            />
                            <div className='text-dark'>
                                <a href="https://www.facebook.com/prolinkengineers" class="mx-1 ">
                                    <i class="fab fa-facebook" style={{ fontSize: '2rem' ,color:"#26ACE2"}}></i>
                                </a>
                                {/* <a href="" class="mx-1 icons">
                                    <i class="fab fa-twitter" style={{ fontSize: '2rem',color:"#FD0303" }}> </i>
                                </a>
                                <a href="" class="mx-1 icons">
                                    <i class="fab fa-google" style={{ fontSize: '2rem',color:"#FD0303" }}></i>
                                </a> */}
                                <a href="https://www.instagram.com/prolinkengineers/" class="mx-1 ">
                                    <i class="fab fa-instagram" style={{ fontSize: '2rem',color:"#26ACE2" }}></i>
                                </a>
                                <a href="" class="mx-1 " >
                                    <i class="fab fa-linkedin" style={{ fontSize: '2rem',color:"#26ACE2" }}></i>
                                </a>
                               
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-12">
                        <h6 class="text-uppercase fw-bold text-white">Contact</h6>
                            <hr
                                class=" mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#ffffff", height: "6px" }}
                            />
                            <p><i class="fas fa-home mr-3">&nbsp;&nbsp;</i>Plot No 6A, New DLF Industrial Area Faridabad, Haryana, India 121003</p>
                            <p><i class="fas fa-envelope mr-3">&nbsp;&nbsp;</i>Info@prolinkengineers.com</p>
                            <p><i class="fas fa-phone mr-3">&nbsp;&nbsp;</i>+91 882-600-7816</p>
                            {/* <Link to="/login" className='btn btn-sm  bg-dark rounded-pill d-none d-lg-block' style={{ fontSize: '1rem' }}> <span style={{ color: "#fff" }}>Admin Login</span></Link> */}
                            {/* <p><i class="fas fa-phone mr-3">&nbsp;&nbsp;</i>+91-8445226722 </p> */}
                        </div>
                    </div>

                </div>

                {/* <div className="container">
                    <div className="row">
                        <div className="col-lg-4 d-flex flex-column text-start">
                            <Link to="/saras-reengineered">SARAS The Re-engineer ed</Link>
                            <Link to="/cbse-affiliation-process">CBSE Affiliation Process</Link>
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4"></div>
                    </div>
                    </div> */}

                {/* <div class="text-center p-3">
                    © 2022 Copyright:
                    <a style={{ color: '#ec661f' }} href="digisidekick.com"> Digisidekick.com</a>
                </div> */}
            </div>
            {/* <div id="whatsapp" class="whatsapp">
                <a href="https://wa.link/hqfmq0" target="_blank" title="Whatsapp" data-bs-toggle="tooltip" data-bs-placement="top">
                    <i class="fab fa-whatsapp"></i>
                </a>
            </div>
            <div className="text-center text-white py-3" style={{ backgroundColor: 'RGBA(1, 1, 0,0.9)' }}>
                   <a href="https://www.facebook.com/jdmorgaan/" className="text-white"> <i class="fa-brands fa-facebook me-5" style={{fontSize:'2rem'}}></i></a>
                   <a href="https://instagram.com/jdmorgaan_whaleslinen?igshid=YmMyMTA2M2Y= "  className="text-white"> <i class="fa-brands fa-instagram" style={{fontSize:'2rem'}}></i></a>
            </div> */}
            {/* Footer=== */}

        </div>
   
   </>
  )
}

export default Footer