import React from 'react'

const BelowLine = () => {
  return (
    <>
     <span style={{ width: "100px", margin: "auto", height: "3px", backgroundColor:"rgb(38, 172, 226)" }} > </span>
            <span></span>
    
    </>
  )
}

export default BelowLine