import React from 'react';
import ProductCard from './ProductCard';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import TopBanner from '../../components/topbanner/TopBanner';
import OurClient from '../../components/ourclient/OurClient';



const Products = () => {

    const products = [
        {
            ProductName: "4G Camera",
            ProductDescription: "lorem24 nmdbaMSD, ADNAS D NDJKND NA",
            key: 1,
            imgsrc: require(`../../images/icons/bulletcamera.jpg`),
            link:"/smart-4g"

        }, {
            ProductName: "Smart Bullet",
            ProductDescription: "lorem24 nmdbaMSD, ADNAS D NDJKND NA",
            key: 2,
            link: "/smart-bullet",
            imgsrc: require(`../../images/icons/bullet.jpg`),
        }, {
            ProductName: "Smart Wifi",
            ProductDescription: "lorem24 nmdbaMSD, ADNAS D NDJKND NA",
            key: 3,
            link: "/smart-wifi",
            imgsrc: require(`../../images/icons/domevandel.jpg`),
        }
    ]


    return (
        <>
            <Header />

            <TopBanner heading="All Products" />

            <div className="container">
                <div className='blog-wrapper-new'>
                    {
                        products.map((p) => (
                            <ProductCard name={p.ProductName} content={p.ProductDescription} link={p.link} imgsrc={p.imgsrc} />
                        ))
                    }
                </div>
            </div>

            <OurClient />


            <Footer />


            {/* <ProductCard/>
    <ProductCard/>
    <ProductCard/> */}
        </>
    )
}

export default Products