import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
// import gallery from './JsonGallery'
import "./gallery.css";
import TopBanner from '../../components/topbanner/TopBanner';



const Gallery = () => {
    return (
        <>
            <Header />
            <TopBanner heading="Our Gallery"/>
            {/* <div className='' style={{marginTop:"120px "}}>
               <div className="row py-5">
                <div className="col-md-12">
                <h1 className='text-center'><b style={{ color: "#147EFB" }}>Our </b> <b>Gallery</b></h1>
                </div>

               </div>
            </div> */}


            {/* <section className="gallery ">
                <div class="container photos_container">
                    <div class="gallery d-flex flex-wrap align-items-center justify-content-center">
                        {
                            gallery.map((item) => (
                                <>
                                    <a href={item.imgsrc} data-lightbox="models" >
                                        <img src={item.imgsrc} style={{height:"300px",width:"300px"}}/>
                                    </a>

                                </>

                            ))
                        }
                    </div>
                </div>
            </section> */}

            

            <Footer />


        </>
    )
}

export default Gallery